// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/fonts/ABCWhyteInktrap-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "WhyteInktrap";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html, body {
  color: #222228;
}

body > div {
  display: flex;
}

html, html > body, html > body > div {
  width: 100%;
}

.MainLayout_main__XDITe {
  flex: 1 1;
  width: 100%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.MainLayout_main__XDITe .MainLayout_content__p8nHO {
  margin-top: 70px;
  margin-bottom: 50px;
  flex: 1 1;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/core/styles.scss","webpack://./src/layouts/MainLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,+DAAA;ACCF;AD0BA;EACE,sBAAA;EACA,gCAzBS;ACCX;;AD2BA;EACE,cAVY;ACdd;;AAVA;EACE,aAAA;AAaF;;AAVA;EACE,WAAA;AAaF;;AAVA;EACE,SAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;AAaF;AAXE;EACE,gBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;AAaJ","sourcesContent":["@font-face {\n  font-family: 'WhyteInktrap';\n  src: url('./../assets/fonts/ABCWhyteInktrap-Book.otf') format('opentype');\n}\n\n//FONTS\n$mainFont: 'Inter', sans-serif;\n$secondaryFont: 'WhyteInktrap', sans-serif;\n\n// COLORS\n$lightBlue: #DDEAFB;\n$mediumBlue: #B4E1FA;\n$blue: #23A0E5;\n$darkBlue: #60748E;\n$lightGrey: #EAECF0;\n$lightOrange: rgba(250, 182, 145, 0.76);\n$orange: #FF7427;\n$red: #C60C30;\n$plusGreen: #EBFF99;\n$lightGreen: #7ADDAF;\n$green: #007940;\n$darkGreen: #112C22;\n$purple: #D7B7FF;\n$lightPurple: #DBD7FC;\n\n$primaryDark: #222228;\n$secondaryDark: #122D62;\n$white: #FFFFFF;\n\n* {\n  box-sizing: border-box;\n  font-family: $mainFont;\n}\n\nhtml, body {\n  color: $primaryDark;\n}","@import '../core/styles';\nbody > div {\n  display: flex;\n}\n\nhtml, html > body, html > body > div {\n  width: 100%;\n}\n\n.main {\n  flex: 1;\n  width: 100%;\n  position: relative;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .content {\n    margin-top: 70px;\n    margin-bottom: 50px;\n    flex: 1;\n    overflow-y: scroll;\n    display: flex;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MainLayout_main__XDITe`,
	"content": `MainLayout_content__p8nHO`
};
export default ___CSS_LOADER_EXPORT___;

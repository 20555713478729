const multiSelectNameMockUp = [
  {
    id: 1,
    label: "Test 2 IBRA",
  },
  {
    id: 2,
    label: "TEST IBRA",
  },
  {
    id: 3,
    label: "XX TEST AUDIT",
  },
  {
    id: 4,
    label: "TEST AUDIT X",
  },
  {
    id: 5,
    label: "Test 3 IBRA",
  },
  {
    id: 6,
    label: "Jaarlijkse A5 audit",
  },
  {
    id: 7,
    label: "Migratie van QMS klant",
  },
  {
    id: 8,
    label: "Yearly (test) Audit D2 - Debtors KDZ",
  },
  {
    id: 9,
    label: "IT Audit dummy",
  },
  {
    id: 10,
    label: "Test wouter",
  },
  {
    id: 11,
    label: "Test Audit voor luara H2",
  },
  {
    id: 12,
    label: "dummyyyy",
  },
  {
    id: 13,
    label: "test visitors access",
  },
];

export default multiSelectNameMockUp;

import React, { useEffect, useState } from "react";
import Modal from "../components/Modal/Modal";
import styles from "./ValidationsModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../components/icons/cross-dark.svg";
import { hideModal } from "../redux/modalStore";
import Accordion from "../components/Accordion/Accordion";
import classNames from "classnames";
import _ from "lodash";

const ValidationsModal = ({ issueData, issuesData }) => {
  const openedModal = useSelector((state) => state.modal.openedModal);

  const dispatch = useDispatch();

  const [mergedData, setMergedData] = useState(null);

  const openedModalData = openedModal?.data;

  useEffect(() => {
    // Check if openedModalData exists and has subtasks
    if (openedModalData && openedModalData?.subtasks) {
      const updatedData = { ...issueData };
      openedModalData.subtasks.forEach((subtask) => {
        if (subtask && subtask.validation) {
          const subtaskUUID = subtask.validation.UUID;
          if (subtaskUUID) {
            if (updatedData[subtaskUUID]) {
              updatedData[subtaskUUID] = updatedData[subtaskUUID].map(
                (issue) => {
                  if (issue) {
                    if (!issue.subtaskData) {
                      issue.subtaskData = {};
                    }
                    issue.subtaskData = subtask;
                  }
                  return issue;
                },
              );
            }
          }
        }
      });
      setMergedData(updatedData);
    }
  }, [openedModalData, issueData]);

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  const issueArray = [];

  for (const key in mergedData) {
    mergedData[key].forEach((item) => {
      issueArray.push({
        date: item.date,
        email: item.email,
        response: item.response,
        subtaskData: item.subtaskData,
      });
    });
  }

  // Opening link from the description in a new tab for In Progress modals
  const modifyDescription = (description) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(description, "text/html");
    const links = doc.querySelectorAll("a");

    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });

    return doc.body.innerHTML;
  };

  return (
    <Modal name="validations_modal" title="Validations modal">
      <div className={styles.header}>
        <h4>{openedModalData?.audit?.Name}</h4>
        <img
          src={CloseIcon}
          className={styles.icon}
          onClick={handleCloseModal}
          alt="Close Icon"
        />
      </div>
      <div className={styles.content}>
        {openedModalData?.status === "Done" ? (
          issueArray.map((issue, index) => {
            let riskNumber = issue?.subtaskData?.risk?.id.toString();

            if (riskNumber < 100) {
              riskNumber = riskNumber.padStart(3, "0");
            }

            return (
              <div key={index}>
                <div className={styles.title}>
                  {issue?.subtaskData?.summary}
                </div>
                <div className={styles.wrapper}>
                  <div
                    className={styles.subtitle}
                  >{`Risk: RI-${riskNumber}`}</div>
                  <div className={styles.text}>
                    {issue?.subtaskData?.risk?.description}
                  </div>
                  <Accordion
                    items={_.map(issue.response, (answers, question) => ({
                      question,
                      answer: answers.length > 0 ? answers : "",
                    }))}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className={styles.firstRow}>
              <div>{openedModalData?.key}</div>
              <div
                className={classNames(
                  styles.statusCell,
                  openedModalData?.status === "In Progress"
                    ? styles.inProgressStatus
                    : styles.openStatus,
                )}
              >
                {openedModalData?.status}
              </div>
            </div>
            <div className={styles.date}>Due date: {openedModalData?.date}</div>
            <div
              dangerouslySetInnerHTML={{
                __html: modifyDescription(openedModalData?.description),
              }}
            />
          </>
        )}
        {issueData?.length === 0 && openedModalData?.status === "Done" && (
          <div className={styles.noData}>
            Sorry, there is no data to display.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ValidationsModal;

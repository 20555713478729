import axiosInstance from "./../instances/axios";

export const loadIssues = (orgId, selectedDate) => {
  // Ensure selectedDate is a valid Date object
  if (!(selectedDate instanceof Date) || isNaN(selectedDate)) {
    selectedDate = new Date(); // Use current date if selectedDate is not valid
  }

  // Calculate the number of days between selected date and current date
  const daysDifference = Math.floor(
    (Date.now() - selectedDate.getTime()) / (1000 * 60 * 60 * 24),
  );

  const options = {
    method: "GET",
    url: `/jira/issues?org_id=${orgId}&issue_type=audits&days=${daysDifference}&link_airtable_data=true`,
  };

  return axiosInstance
    .request(options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const loadIssue = (orgId, key) => {
  const options = {
    method: "GET",
    url: `/nocodb/validation_submissions?org_id=${orgId}&audit_issue_key=${key}`,
  };

  return axiosInstance
    .request(options)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

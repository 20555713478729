// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/ABCWhyteInktrap-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "WhyteInktrap";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html, body {
  color: #222228;
}

.Accordion_container__EMzF0 {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.Accordion_container__EMzF0 .Accordion_button__MtIgp {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.Accordion_container__EMzF0 .Accordion_button__MtIgp span {
  font-size: 14px;
  font-weight: 500;
  text-align: justify;
}
.Accordion_container__EMzF0 .Accordion_button__MtIgp .Accordion_icon__ukPv- {
  cursor: pointer;
  margin-left: auto;
}
.Accordion_container__EMzF0 .Accordion_content__5LU2x {
  background-color: #DBD7FC;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
}
.Accordion_container__EMzF0 .Accordion_content__5LU2x .Accordion_text__-4oGi {
  font-size: 12px;
  text-align: justify;
}
.Accordion_container__EMzF0 .Accordion_content__5LU2x img {
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/core/styles.scss","webpack://./src/components/Accordion/Accordion.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,+DAAA;ACCF;AD0BA;EACE,sBAAA;EACA,gCAzBS;ACCX;;AD2BA;EACE,cAVY;ACdd;;AATA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;AAYF;AAVE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAYJ;AAVI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAYN;AATI;EACE,eAAA;EACA,iBAAA;AAWN;AAPE;EACE,yBDHU;ECIV,kBAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;AASJ;AAPI;EACE,eAAA;EACA,mBAAA;AASN;AANI;EACE,eAAA;AAQN","sourcesContent":["@font-face {\n  font-family: 'WhyteInktrap';\n  src: url('./../assets/fonts/ABCWhyteInktrap-Book.otf') format('opentype');\n}\n\n//FONTS\n$mainFont: 'Inter', sans-serif;\n$secondaryFont: 'WhyteInktrap', sans-serif;\n\n// COLORS\n$lightBlue: #DDEAFB;\n$mediumBlue: #B4E1FA;\n$blue: #23A0E5;\n$darkBlue: #60748E;\n$lightGrey: #EAECF0;\n$lightOrange: rgba(250, 182, 145, 0.76);\n$orange: #FF7427;\n$red: #C60C30;\n$plusGreen: #EBFF99;\n$lightGreen: #7ADDAF;\n$green: #007940;\n$darkGreen: #112C22;\n$purple: #D7B7FF;\n$lightPurple: #DBD7FC;\n\n$primaryDark: #222228;\n$secondaryDark: #122D62;\n$white: #FFFFFF;\n\n* {\n  box-sizing: border-box;\n  font-family: $mainFont;\n}\n\nhtml, body {\n  color: $primaryDark;\n}","@import '../../core/styles';\n\n.container {\n  margin-top: 24px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 10px;\n\n  .button {\n    display: flex;\n    align-items: center;\n    column-gap: 20px;\n\n    span {\n      font-size: 14px;\n      font-weight: 500;\n      text-align: justify;\n    }\n\n    .icon{\n      cursor: pointer;\n      margin-left: auto;\n    }\n  }\n\n  .content {\n    background-color: $lightPurple;\n    border-radius: 8px;\n    padding: 12px;\n    display: flex;\n    align-items: flex-start;\n    column-gap: 8px;\n\n    .text {\n      font-size: 12px;\n      text-align: justify;\n    }\n\n    img {\n      margin-top: 4px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Accordion_container__EMzF0`,
	"button": `Accordion_button__MtIgp`,
	"icon": `Accordion_icon__ukPv-`,
	"content": `Accordion_content__5LU2x`,
	"text": `Accordion_text__-4oGi`
};
export default ___CSS_LOADER_EXPORT___;

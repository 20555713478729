import React from "react";
import styles from "./Modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { hideModal } from "../../redux/modalStore";
import OutsideClickHandler from "react-outside-click-handler";

const Modal = (props) => {
  const openedModal = useSelector((state) => state.modal.openedModal);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(hideModal(null));
  };

  return openedModal?.name === props.name ? (
    <div className={styles.modal}>
      <OutsideClickHandler onOutsideClick={handleCloseModal}>
        <div className={styles.box}>
          <div>{props.children}</div>
        </div>
      </OutsideClickHandler>
    </div>
  ) : (
    ""
  );
};

export default Modal;

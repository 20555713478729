import React from "react";
import { Tooltip } from "react-tooltip";
import styles from "./TooltipProvider.module.scss";

const TooltipProvider = () => {
  return (
    <Tooltip
      id="tooltip"
      className={styles.wrapper}
      disableStyleInjection={true}
    />
  );
};

export default TooltipProvider;

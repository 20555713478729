// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/fonts/ABCWhyteInktrap-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "WhyteInktrap";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("opentype");
}
* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

html, body {
  color: #222228;
}

.Separator_separator__cpSDq {
  background: #EAECF0;
  display: flex;
  height: 1px;
  width: 100%;
}

.Separator_vertical__hXwgz {
  margin: 0 8px;
  height: 28px;
  width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/core/styles.scss","webpack://./src/components/Separator/Separator.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,+DAAA;ACCF;AD0BA;EACE,sBAAA;EACA,gCAzBS;ACCX;;AD2BA;EACE,cAVY;ACdd;;AATA;EACE,mBDWU;ECVV,aAAA;EACA,WAAA;EACA,WAAA;AAYF;;AATA;EACE,aAAA;EACA,YAAA;EACA,UAAA;AAYF","sourcesContent":["@font-face {\n  font-family: 'WhyteInktrap';\n  src: url('./../assets/fonts/ABCWhyteInktrap-Book.otf') format('opentype');\n}\n\n//FONTS\n$mainFont: 'Inter', sans-serif;\n$secondaryFont: 'WhyteInktrap', sans-serif;\n\n// COLORS\n$lightBlue: #DDEAFB;\n$mediumBlue: #B4E1FA;\n$blue: #23A0E5;\n$darkBlue: #60748E;\n$lightGrey: #EAECF0;\n$lightOrange: rgba(250, 182, 145, 0.76);\n$orange: #FF7427;\n$red: #C60C30;\n$plusGreen: #EBFF99;\n$lightGreen: #7ADDAF;\n$green: #007940;\n$darkGreen: #112C22;\n$purple: #D7B7FF;\n$lightPurple: #DBD7FC;\n\n$primaryDark: #222228;\n$secondaryDark: #122D62;\n$white: #FFFFFF;\n\n* {\n  box-sizing: border-box;\n  font-family: $mainFont;\n}\n\nhtml, body {\n  color: $primaryDark;\n}","@import '../../core/styles';\n\n.separator {\n  background: $lightGrey;\n  display: flex;\n  height: 1px;\n  width: 100%;\n}\n\n.vertical {\n  margin: 0 8px;\n  height: 28px;\n  width: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `Separator_separator__cpSDq`,
	"vertical": `Separator_vertical__hXwgz`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";
import styles from "./Input.module.scss";
import SearchIcon from "./../icons/search.svg";
import classNames from "classnames";

const Input = ({ type, placeholder, value, onChange }) => {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <div className={classNames(styles.inputWrapper, focus && styles.focus)}>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <img src={SearchIcon} alt="Search icon" />
    </div>
  );
};

export default Input;

import React from "react";
import styles from "./Button.module.scss";

const Button = ({ buttonText, icon, onClick, width }) => {
  return (
    <button
      className={styles.buttonWrapper}
      type="button"
      onClick={onClick}
      style={{ width: width }}
    >
      <img src={icon} alt="Button icon" />
      {buttonText}
    </button>
  );
};

export default Button;

import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { xor } from "lodash";
import styles from "./MultiSelect.module.scss";
import DownArrowIcon from "./../icons/down-arrow.svg";
import CrossIcon from "./../icons/cross.svg";

function MultiSelect({
  label,
  options,
  onChangeSelectedValues,
  selectedValues,
}) {
  const [multiSelectActive, setMultiSelectActive] = useState(false);

  const handleCloseMultiSelect = () => {
    setMultiSelectActive(false);
  };

  const handleClearFilters = () => {
    onChangeSelectedValues([]);
  };

  return (
    <div className={styles.wrapper}>
      {/*<div*/}
      {/*  className={classNames(*/}
      {/*    styles.filterIconWrapper,*/}
      {/*    selectedValues?.length && styles.filtersActive,*/}
      {/*  )}*/}
      {/*  onClick={handleClearFilters}*/}
      {/*  data-tooltip-id={selectedValues?.length ? "tooltip" : null}*/}
      {/*  data-tooltip-content={*/}
      {/*    selectedValues?.length ? "clear all filters" : null*/}
      {/*  }*/}
      {/*/>*/}
      <div
        className={styles.button}
        onMouseDown={() => setMultiSelectActive(!multiSelectActive)}
        onKeyDown={() => setMultiSelectActive(!multiSelectActive)}
      >
        <img src={DownArrowIcon} className={styles.downArrowIcon} />
        <span>{label}</span>
        {selectedValues?.length > 0 && (
          <div
            className={styles.crossIconWrapper}
            onClick={handleClearFilters}
          />
        )}
      </div>
      <OutsideClickHandler
        onOutsideClick={handleCloseMultiSelect}
        disabled={!multiSelectActive}
      >
        {multiSelectActive && options && (
          <div className={styles.options}>
            {options.map((option) => (
              <div
                key={option.id}
                className={styles.option}
                onClick={() => {
                  onChangeSelectedValues(xor(selectedValues, [option.label]));
                }}
                onKeyDown={() =>
                  onChangeSelectedValues(xor(selectedValues, [option.label]))
                }
              >
                <div
                  className={classNames(styles.checkbox, {
                    [styles.active]: selectedValues.includes(option.label),
                  })}
                />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
}

export default MultiSelect;

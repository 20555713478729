import React, { useState } from "react";
import styles from "./Table.module.scss";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";

const columns = [
  {
    accessorKey: "key",
    header: "Issue Key",
    cell: (props) => <div>{props.getValue()}</div>,
  },
  {
    accessorKey: "name",
    header: "Name",
    size: 250,
    cell: (props) => <div>{props.row.original.audit.Name}</div>,
  },
  {
    accessorKey: "assignee",
    header: "Assignee",
    size: 250,
    cell: (props) => <div>{props.getValue()}</div>,
  },
  {
    accessorKey: "status",
    header: "Status",
    minSize: 90,
    cell: (props) => {
      const status = props.getValue();
      let statusClassName = "";

      switch (status) {
        case "Open":
          statusClassName = styles.openStatus;
          break;
        case "In Progress":
          statusClassName = styles.inProgressStatus;
          break;
        case "Done":
          statusClassName = styles.resolvedStatus;
          break;
        default:
          break;
      }

      return (
        <div className={classNames(styles.statusCell, statusClassName)}>
          {status}
        </div>
      );
    },
  },
  {
    accessorKey: "date",
    header: "Due Date",
    cell: (props) => <div>{props.getValue()}</div>,
  },
  {
    accessorKey: "frequency",
    header: "Frequency",
    cell: (props) => (
      //Making first letter uppercase
      <div>
        {props.row.original.audit.Frequency.charAt(0).toUpperCase() +
          props.row.original.audit.Frequency.slice(1)}
      </div>
    ),
  },
  {
    accessorKey: "chapter",
    header: "Chapter",
    cell: (props) => (
      <div>{props.row.original.audit["QMS Chapter"].join(", ")}</div>
    ),
    size: 150,
  },
];

const Table = ({ data, onRowClick }) => {
  const handleRowClick = (row) => {
    onRowClick(row);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
  });

  return (
    <>
      <div
        className={styles.tableContainer}
        style={{ width: table.getCenterTotalSize() }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <div className={styles.tr} key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <div
                className={styles.th}
                style={{ width: header.getSize() }}
                key={header.id}
              >
                {header?.column?.columnDef?.header}
                <div
                  onMouseDown={header.getResizeHandler()}
                  className={classNames(
                    styles.resizer,
                    header.column.getIsResizing() && styles.isResizing,
                  )}
                />
              </div>
            ))}
          </div>
        ))}
        {table.getRowModel().rows.map((row) => (
          <div
            className={styles.tr}
            key={row.id}
            onClick={() => handleRowClick(row)}
          >
            {row.getVisibleCells().map((cell) => (
              <div
                className={classNames(
                  styles.td,
                  (cell.column.columnDef.header === "Status" ||
                    cell.column.columnDef.header === "Chapter") &&
                    styles.centeredData,
                )}
                style={{ width: cell.column.getSize() }}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

export default Table;

import React from "react";
import styles from "./MainLayout.module.scss";
import TooltipProvider from "../components/TooltipProvider/TooltipProvider";

const MainLayout = (props) => {
  return (
    <>
      <TooltipProvider />
      <div className={styles.main}>
        <div className={styles.content}>{props.children}</div>
      </div>
    </>
  );
};

export default MainLayout;

import React, { useEffect, useState } from "react";
import styles from "./Validations.module.scss";
import Table from "../../components/Table/Table";
import MultiSelect from "../../components/MultiSelect/MultiSelect";
import "react-datepicker/dist/react-datepicker.css";
import multiSelectNameMockUp from "../../components/MultiSelect/multiSelectNameMockUp";
import DateFilter from "../../components/DateFilter/DateFilter";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import FilterIcon from "./../../components/icons/filterThin.svg";
import classNames from "classnames";
import { showModal } from "../../redux/modalStore";
import { useDispatch } from "react-redux";
import ValidationsModal from "../../modals/ValidationsModal";
import { loadIssue, loadIssues } from "../../actions/validations";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

const Validations = () => {
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [data, setData] = useState([]);
  const [selectedIssueData, setSelectedIssueData] = useState(null);

  const dispatch = useDispatch();

  const { orgId } = useParams();

  const {
    data: issuesData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["issues", startDate],
    isRefetching: {
      keepPreviousData: true,
      // staleTime: 5 * 60 * 1000,
    },
    queryFn: () => loadIssues(orgId, startDate),
  });

  // Extracting unique chapters by merging qms_id and qms_description from subtasks and getting array of description
  const chapters = [
    ...new Set(
      issuesData?.flatMap((issue) =>
        issue?.subtasks?.map(
          (subtask) =>
            `${subtask?.risk?.qms_id} - ${subtask?.risk?.qms_description}`,
        ),
      ),
    ),
  ];

  // Creating array of objects with ids and labels which is used in chapter filters
  const uniqueChapters = [];

  chapters?.forEach((label, index) => {
    const id = index + 1;
    uniqueChapters?.push({ id, label });
  });

  useEffect(() => {
    if (issuesData) {
      // Once issuesData is available, set it into the state
      setData(issuesData);
    }
  }, [issuesData]);

  if (isLoading)
    return (
      <div className={styles.loader}>
        <div className={styles.spinner} />
        <span>Loading, thank you for your patience...</span>
      </div>
    );
  if (error)
    return (
      <div className={styles.error}>
        We encountered an error while retrieving your data. Please refresh the
        page and try again.
      </div>
    );

  // Chapter filter
  const handleChapterFilterChange = (newSelectedValues) => {
    setSelectedChapters(newSelectedValues);
    if (newSelectedValues.length === 0) {
      // If no options are selected, display full data
      setData(issuesData);
    } else {
      // Filter data based on selected chapters
      const filteredData = issuesData.filter((issue) =>
        issue.subtasks.some((subtask) => {
          return newSelectedValues.some((value) =>
            value.includes(subtask.risk.qms_id),
          );
        }),
      );

      setData(filteredData);
    }
  };

  const auditNames = issuesData.map((issue, index) => {
    return {
      id: index + 1,
      label: issue.audit.Name,
    };
  });

  // Audit Name filter
  const handleNameFilterChange = (newSelectedValues) => {
    setSelectedNames(newSelectedValues);
    if (newSelectedValues.length === 0) {
      // If no options are selected, display full data
      setData(issuesData);
    } else {
      // Filter data based on selected names
      const filteredData = issuesData.filter((item) =>
        newSelectedValues.includes(item.audit.Name),
      );
      setData(filteredData);
    }
  };

  // Date filter
  const handleDateFilterChange = (date) => {
    setStartDate(date);

    if (!date) {
      // If no date is selected, reset data to the original mockup
      setData(issuesData);
    } else {
      const filteredData = issuesData.filter((item) => {
        const dueDate = new Date(
          item.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"),
        );
        const selectedDate = new Date(date);

        // Include dates on the same day or after the selected date
        return dueDate >= selectedDate.setHours(0, 0, 0, 0);
      });
      setData(filteredData);
    }
  };

  // Search filter TODO: Make it more readable
  const filteredData = data?.filter((item) => {
    const { audit } = item;

    if (audit) {
      const { Name, Frequency, "QMS Chapter": chapters } = audit;

      return (
        item.key?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.assignee?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.status?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        Name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        Frequency?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        chapters?.some((chapter) =>
          chapter?.toLowerCase().includes(searchQuery.toLowerCase()),
        )
      );
    }

    return false;
  });

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleRowClick = async (row) => {
    try {
      const issueData = await loadIssue(orgId, row.original.key);
      setSelectedIssueData(issueData);
      dispatch(
        showModal({
          name: "validations_modal",
          data: row.original,
        }),
      );
    } catch (error) {
      console.error("Error loading issue data:", error);
    }
  };

  return (
    <div className={styles.validationContainer}>
      <ValidationsModal issueData={selectedIssueData} issuesData={issuesData} />
      <div className={styles.rowWrapper}>
        <div className={styles.firstRow}>
          <Button
            buttonText="Filters"
            icon={FilterIcon}
            onClick={handleShowFilters}
            width={90}
          />
          <Input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div
          className={classNames(
            styles.filtersWrapper,
            showFilters && styles.activeFilters,
          )}
        >
          <MultiSelect
            label={
              selectedChapters?.length > 0
                ? selectedChapters.join(", ")
                : "All chapters"
            }
            options={uniqueChapters}
            onChangeSelectedValues={handleChapterFilterChange}
            selectedValues={selectedChapters}
          />
          <MultiSelect
            label={
              selectedNames?.length > 0
                ? selectedNames.join(", ")
                : "All audits"
            }
            options={auditNames}
            onChangeSelectedValues={handleNameFilterChange}
            selectedValues={selectedNames}
          />
          <DateFilter
            handleDateFilterChange={handleDateFilterChange}
            startDate={startDate}
          />
        </div>
      </div>
      <Table
        data={filteredData ? filteredData : issuesData}
        onRowClick={handleRowClick}
      />
      {(!data?.length || !filteredData?.length) && (
        <div className={styles.noData}>Sorry, there is no data to display.</div>
      )}
    </div>
  );
};

export default Validations;

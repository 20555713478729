import React, { useEffect, useState } from "react";
import styles from "./Accordion.module.scss";
import QuestionMarkIcon from "./../icons/question-mark.svg";
import MinusIcon from "./../icons/minus-circle.svg";
import PlusIcon from "./../icons/plus-circle.svg";
import ArrowIcon from "./../icons/arrow.svg";
import Separator from "../Separator/Separator";

const Accordion = ({ items }) => {
  const [openedAccordions, setOpenedAccordions] = useState([]);

  const toggleAccordion = (index) => {
    if (openedAccordions.includes(index)) {
      setOpenedAccordions(openedAccordions.filter((i) => i !== index)); // Close if already open
    } else {
      setOpenedAccordions([...openedAccordions, index]); // Open if closed
    }
  };

  return items?.map((item, index) => {
    const isOpen = openedAccordions.includes(index);
    return (
      <div className={styles.container} key={index}>
        <div className={styles.button}>
          <img src={QuestionMarkIcon} alt="Question mark icon" />
          <span>{item.question}</span>
          {item.answer.length > 0 &&
            // item?.answer?.trim().toLowerCase() !== "n.a" &&
            (!isOpen ? (
              <img
                src={PlusIcon}
                alt="Open icon"
                className={styles.icon}
                onClick={() => toggleAccordion(index)}
              />
            ) : (
              <img
                src={MinusIcon}
                alt="Close icon"
                className={styles.icon}
                onClick={() => toggleAccordion(index)}
              />
            ))}
        </div>
        {isOpen && (
          <div className={styles.content}>
            <img src={ArrowIcon} />
            {Array.isArray(item.answer) ? (
              item.answer.map((answer, answerIndex) => (
                <div className={styles.text} key={answerIndex}>
                  {answer}
                </div>
              ))
            ) : (
              <div className={styles.text}>{item.answer}</div>
            )}
          </div>
        )}
        <Separator />
      </div>
    );
  });
};

export default Accordion;
